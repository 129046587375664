import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { ErrorCodes, getCurrentUserType, getCustomerIdentification, IUserType, LOGGED_USER_KEYS, retrieveCartCookie } from "@vfit/consumer/data-access";
import { NEXT_ERR_MSG, NEXT_ERR_TRANSACTION_ID } from "@vfit/shared/providers";
import { removeCurrency } from "@vfit/consumer/hooks";
import { checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import crypto from "crypto-es";
import { DEFAULT_DATA } from "../iBuyMobile.context.data";
import { COMPONENTS_FLOW, MOBILE_LINE_FLOW } from "./checkout.flow";
import { DEFAULT_CURRENT_STEP_KEY, ID_FLOWS } from "./checkout.constants";
import { getSerializeContext, retrieveProduct } from "../iBuyMobile.utils";
// region ERROR UTILS
var handleCRMErrorCodes = function(backendError) {
    switch(backendError){
        case "CRM-0020":
        case "CRM-0024":
            return ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT;
        case "CRM-0021":
        case "CRM-0023":
            return ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT;
        case "CRM-0022":
        case "CRM-0025":
            return ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT;
        default:
            return "";
    }
};
// endregion
// region UTILS
var isErrorOnPaymentGeneric = function() {
    var ref;
    if (!checkWindow()) return false;
    var queryString = window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    return queryString.includes(ErrorCodes.ERROR_ON_PAYMENT);
};
var isErrorOnInstant = function() {
    var ref;
    if (!checkWindow()) return false;
    var queryString = window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    return queryString.includes(ErrorCodes.MODE_BACK_SIA_INSTANT);
};
var isDoneOnRecurrent = function() {
    var ref;
    if (!checkWindow()) return false;
    var queryString = window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search;
    return queryString.includes(ErrorCodes.MODE_DONE_SIA_RECURRENT);
};
var isEnabledVoucher = function(product) {
    return product.enableVoucher || false;
};
var getActiveSlideFromStorage = function() {
    var ctx = getSerializeContext();
    if (!ctx) return false;
    if (isDoneOnRecurrent()) {
        return ID_FLOWS.UPFRONT_PAYMENT;
    }
    return (ctx === null || ctx === void 0 ? void 0 : ctx.currentStepKey) || DEFAULT_CURRENT_STEP_KEY;
};
var organizeFlowsAppend = function(flowCards, enableAppend, idFromStep, appendSteps) {
    var indexAppend = flowCards.findIndex(function(el) {
        return el.title === idFromStep;
    });
    var organizedFlows = flowCards.slice(0, indexAppend + 1);
    var lastFlows = flowCards.slice(indexAppend + 1);
    if (enableAppend) {
        var alreadyPresent = appendSteps[0].title === lastFlows[0].title;
        if (alreadyPresent) return flowCards;
        organizedFlows = organizedFlows.concat(appendSteps);
        organizedFlows = organizedFlows.concat(lastFlows);
    } else if (lastFlows[0].title === appendSteps[0].title) {
        var removedAppendInfo = lastFlows.slice(appendSteps.length);
        organizedFlows = organizedFlows.concat(removedAppendInfo);
    } else if ((appendSteps === null || appendSteps === void 0 ? void 0 : appendSteps.findIndex(function(aS) {
        return aS.title === lastFlows[0].title;
    })) > -1) {
        var removedAppendInfo1 = lastFlows.slice(1);
        organizedFlows = organizedFlows.concat(removedAppendInfo1);
    } else {
        organizedFlows = organizedFlows.concat(lastFlows);
    }
    return organizedFlows;
};
var retrieveOwningIndividual = function(useCustomerData) {
    var countries = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    var ref;
    var owningIndividual = useCustomerData === null || useCustomerData === void 0 ? void 0 : useCustomerData.owningIndividual;
    var identification = getCustomerIdentification(owningIndividual);
    if (!owningIndividual) {
        return DEFAULT_DATA.owningIndividual;
    }
    var nationality = countries === null || countries === void 0 ? void 0 : countries.filter(function(element) {
        return element.id === (identification === null || identification === void 0 ? void 0 : identification.nationality);
    });
    var formatDate = function(date) {
        var retrievedDate = date.toString();
        var year = retrievedDate.substring(0, 4);
        var month = retrievedDate.substring(5, 7);
        var day = retrievedDate.substring(8);
        return day.concat("/", month, "/", year);
    };
    var identificationType = "";
    var expirationDate = (identification === null || identification === void 0 ? void 0 : identification.expirationDate) ? formatDate(identification.expirationDate) : "";
    if ((identification === null || identification === void 0 ? void 0 : identification.identificationType) === "1") identificationType = "Carta d'identit\xe0";
    else if ((identification === null || identification === void 0 ? void 0 : identification.identificationType) === "2") identificationType = "Passaporto";
    else if ((identification === null || identification === void 0 ? void 0 : identification.identificationType) === "3") identificationType = "Patente di guida";
    var owningIndividualToReturn = {
        nation: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.nation) || "",
        firstName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.firstName) || "",
        lastName: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.lastName) || "",
        fiscalCode: (owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.fiscalCode) || "",
        identification: [
            {
                identificationType: identificationType,
                nationality: (nationality === null || nationality === void 0 ? void 0 : (ref = nationality[0]) === null || ref === void 0 ? void 0 : ref.displayName) || "",
                identificationNumber: (identification === null || identification === void 0 ? void 0 : identification.identificationNumber) || "",
                expirationDate: expirationDate
            }, 
        ],
        contactMedium: [
            owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.contactMedium[0],
            owningIndividual === null || owningIndividual === void 0 ? void 0 : owningIndividual.contactMedium[1]
        ]
    };
    return owningIndividualToReturn;
};
var checkErrorCustomer = function(errors, errorCode, erroMessage) {
    var error = {
        title: (erroMessage === null || erroMessage === void 0 ? void 0 : erroMessage.title) || "",
        message: (erroMessage === null || erroMessage === void 0 ? void 0 : erroMessage.description) || "",
        actionText: (erroMessage === null || erroMessage === void 0 ? void 0 : erroMessage.buttonLabel) || "",
        url: ""
    };
    var getSpecificError = function(errorCodeLocal, defaultTitle, defaultMessage) {
        var ref, ref1;
        var specificError = errors === null || errors === void 0 ? void 0 : errors.find(function(item) {
            return item.error === errorCodeLocal;
        });
        return {
            title: (specificError === null || specificError === void 0 ? void 0 : specificError.title) || defaultTitle || "",
            message: (specificError === null || specificError === void 0 ? void 0 : specificError.message) || defaultMessage || "",
            actionText: (specificError === null || specificError === void 0 ? void 0 : (ref = specificError.button) === null || ref === void 0 ? void 0 : ref.title) || "",
            url: (specificError === null || specificError === void 0 ? void 0 : (ref1 = specificError.button) === null || ref1 === void 0 ? void 0 : ref1.urlForRedirect) || "",
            isPopup: (specificError === null || specificError === void 0 ? void 0 : specificError.isClickToCall) === "true",
            disableTrack: true
        };
    };
    if (errorCode === ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT) {
        error = getSpecificError(ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT, "Attenzione", "Il numero di telefono inserito \xe8 gi\xe0 esistente");
    }
    if (errorCode === ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT) {
        error = getSpecificError(ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT, "Attenzione", "L'e-mail inserita \xe8 gi\xe0 esistente");
    }
    if (errorCode === ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT) {
        error = getSpecificError(ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT, "Attenzione", "I dati di contatto inseriti sono associati ad un altro utente");
    }
    if (errorCode === ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT) {
        error = getSpecificError(ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT, "Attenzione", "Esiste gi\xe0 un utente con questo codice fiscale.");
    }
    if (errorCode === ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT) {
        error = getSpecificError(ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT, "Attenzione", "Il documento inserito \xe8 gi\xe0 presente");
    }
    if (errorCode === ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS) {
        error = getSpecificError(ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS, "Attenzione", "L'indirizzo selezionato non \xe8 disponibile. Si prega di riprovare pi\xf9 tardi");
    }
    if (errorCode === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED) {
        error = getSpecificError(ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED, "Attenzione", "Questa offerta non \xe8 compatibile per questa tipologia di utente");
    }
    return error;
};
var organizePaymentError = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    return {
        titleErrorPayment: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.paymentsmobile) === null || ref === void 0 ? void 0 : (ref1 = ref.paymentError) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        messageErrorPayment: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref2 = dataFromCms.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.paymentError) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        actionTextErrorPayment: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref4 = dataFromCms.paymentsmobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.paymentError) === null || ref5 === void 0 ? void 0 : ref5.buttonLabel) || ""
    };
};
var organizeOrderNotCompletedError = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    return {
        titleOrderError: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.paymentsmobile) === null || ref === void 0 ? void 0 : (ref1 = ref.orderNotCompletedError) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        messageOrderError: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref2 = dataFromCms.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.orderNotCompletedError) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        actionTextOrderError: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref4 = dataFromCms.paymentsmobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.orderNotCompletedError) === null || ref5 === void 0 ? void 0 : ref5.buttonLabel) || ""
    };
};
var addRemoveVisibilityCards = function(type) {
    var cardsEl = document.getElementById("stepper-cards-container");
    var footerEl = document.getElementById("sticky-offer-stepper");
    if (cardsEl && footerEl) {
        if (type === "hide") {
            cardsEl.style.visibility = "hidden";
            footerEl.style.visibility = "hidden";
        } else {
            cardsEl.style.visibility = "inherit";
            footerEl.style.visibility = "inherit";
        }
    }
};
/**
 * Return slide index by key
 * @param flows
 * @param key
 */ var getSlideIndexByKey = function(flows, key) {
    return flows.findIndex(function(f) {
        return f.title === key;
    });
};
// endregion
// region TAGGING
var getTagging = function(pageType) {
    var type = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "mobile";
    var ref;
    var product = retrieveProduct();
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var isWinBack = product === null || product === void 0 ? void 0 : product.isWinback;
    var trackingProduct = {
        cart_id: (ref = shoppingCart) === null || ref === void 0 ? void 0 : ref.id,
        cart_product_id: "".concat(product === null || product === void 0 ? void 0 : product.offerId),
        cart_product_name: product === null || product === void 0 ? void 0 : product.slug,
        cart_product_category: type,
        cart_product_price: (product === null || product === void 0 ? void 0 : product.price) && removeCurrency(product.price),
        cart_product_quantity: "1",
        cart_total: (product === null || product === void 0 ? void 0 : product.price) && removeCurrency(product.price)
    };
    var opts = {
        event_label: pageType,
        product_target_segment: "consumer",
        event_category: "sales",
        page_subsection: "checkout",
        page_section: isWinBack ? "campaign" : "".concat(type, " offers"),
        page_type: pageType,
        journey_type: "journey",
        journey_name: isWinBack ? "winback" : "checkout"
    };
    var pageProductsInfo = {
        product_name: (product === null || product === void 0 ? void 0 : product.slug) || "",
        product_price: (product === null || product === void 0 ? void 0 : product.price) ? removeCurrency(product === null || product === void 0 ? void 0 : product.price) : "",
        product_id: "".concat(product === null || product === void 0 ? void 0 : product.offerId),
        product_quantity: "1",
        product_category: type
    };
    return {
        trackingProduct: trackingProduct,
        opts: opts,
        pageProductsInfo: pageProductsInfo
    };
};
var getUserInfo = function() {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37, ref38, ref39;
    var SHA256 = crypto.SHA256;
    var user = getFromLocalStorageByKey("user");
    var silentLogin = getFromLocalStorageByKey("silentLogin");
    var loginEnrichement = getFromLocalStorageByKey("loginEnriched");
    var isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin === null || silentLogin === void 0 ? void 0 : silentLogin.flowId) || false;
    var customerData = getFromLocalStorageByKey("customerData");
    var appSessionFromStorage = checkWindow() && localStorage.getItem("appSession");
    var appSession = !!appSessionFromStorage && JSON.parse(appSessionFromStorage);
    var appMsisdn = appSession === null || appSession === void 0 ? void 0 : appSession.value;
    var visitorLoginStatus = appMsisdn || isLoggedUser ? "logged in" : "logged out";
    return _object_spread({
        visitor_login_type: "web"
    }, !!retrieveCartCookie() && {
        visitor_type: "returning visitor"
    }, (user === null || user === void 0 ? void 0 : (ref = user.customer) === null || ref === void 0 ? void 0 : ref[0].id) && {
        visitor_customer_id: user === null || user === void 0 ? void 0 : user.customer[0].id
    }, (user === null || user === void 0 ? void 0 : (ref1 = user.taggedInfoCust) === null || ref1 === void 0 ? void 0 : ref1.email) && {
        visitor_id_email: SHA256(user === null || user === void 0 ? void 0 : (ref2 = user.taggedInfoCust) === null || ref2 === void 0 ? void 0 : ref2.email).toString()
    }, (user === null || user === void 0 ? void 0 : (ref3 = user.customer) === null || ref3 === void 0 ? void 0 : ref3[0].msisdns) && {
        visitor_id_asset_list: (ref5 = user === null || user === void 0 ? void 0 : (ref4 = user.customer) === null || ref4 === void 0 ? void 0 : ref4[0].msisdns) === null || ref5 === void 0 ? void 0 : ref5.map(function(msisdn) {
            var ref;
            return ((ref = SHA256(msisdn)) === null || ref === void 0 ? void 0 : ref.toString()) || "";
        })
    }, loginEnrichement && ((ref6 = loginEnrichement.items[0]) === null || ref6 === void 0 ? void 0 : ref6.value) && {
        visitor_id_asset_active: SHA256((ref7 = loginEnrichement.items[0]) === null || ref7 === void 0 ? void 0 : ref7.value).toString()
    }, appMsisdn && {
        visitor_id_asset_active: SHA256(appMsisdn).toString()
    }, visitorLoginStatus && {
        visitor_login_status: visitorLoginStatus
    }, customerData && ((ref8 = customerData[0]) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.owningIndividual) === null || ref9 === void 0 ? void 0 : ref9.birthDate) && {
        visitor_customer_dob: (ref10 = customerData[0]) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.owningIndividual) === null || ref11 === void 0 ? void 0 : ref11.birthDate
    }, customerData && ((ref12 = customerData[0]) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.owningIndividual) === null || ref13 === void 0 ? void 0 : ref13.gender) && {
        visitor_customer_gender: (ref14 = customerData[0]) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.owningIndividual) === null || ref15 === void 0 ? void 0 : ref15.gender
    }, customerData && ((ref16 = customerData[0]) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.billingAddress) === null || ref17 === void 0 ? void 0 : ref17.city) && {
        visitor_customer_location_city: (ref18 = customerData[0]) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.billingAddress) === null || ref19 === void 0 ? void 0 : ref19.city
    }, customerData && ((ref20 = customerData[0]) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.owningIndividual) === null || ref21 === void 0 ? void 0 : ref21.nation) && {
        visitor_customer_location_country: (ref22 = customerData[0]) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.owningIndividual) === null || ref23 === void 0 ? void 0 : ref23.nation
    }, customerData && ((ref24 = customerData[0]) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.billingAddress) === null || ref25 === void 0 ? void 0 : ref25.postalCode) && {
        visitor_customer_location_id: (ref26 = customerData[0]) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.billingAddress) === null || ref27 === void 0 ? void 0 : ref27.postalCode
    }, customerData && ((ref28 = customerData[0]) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.owningIndividual) === null || ref29 === void 0 ? void 0 : ref29.firstName) && {
        visitor_customer_name_first: (ref30 = customerData[0]) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.owningIndividual) === null || ref31 === void 0 ? void 0 : ref31.firstName
    }, customerData && ((ref32 = customerData[0]) === null || ref32 === void 0 ? void 0 : (ref33 = ref32.owningIndividual) === null || ref33 === void 0 ? void 0 : ref33.lastName) && {
        visitor_customer_name_last: (ref34 = customerData[0]) === null || ref34 === void 0 ? void 0 : (ref35 = ref34.owningIndividual) === null || ref35 === void 0 ? void 0 : ref35.lastName
    }, customerData && ((ref36 = customerData[0]) === null || ref36 === void 0 ? void 0 : (ref37 = ref36.billingAddress) === null || ref37 === void 0 ? void 0 : ref37.stateOrProvince) && {
        visitor_customer_region: (ref38 = customerData[0]) === null || ref38 === void 0 ? void 0 : (ref39 = ref38.billingAddress) === null || ref39 === void 0 ? void 0 : ref39.stateOrProvince
    });
};
// endregion
// region utils FLOWS
/**
 * Return correct user flow by offer
 * @param product
 */ var getUserFlow = function(product) {
    if (!product) return [];
    var flowReturn = MOBILE_LINE_FLOW.DEFAULT;
    if ((product === null || product === void 0 ? void 0 : product.skipMnp) && !(product === null || product === void 0 ? void 0 : product.isEsimOnly)) flowReturn = MOBILE_LINE_FLOW.SKIP_MNP;
    if (!(product === null || product === void 0 ? void 0 : product.skipMnp) && (product === null || product === void 0 ? void 0 : product.isEsimOnly)) flowReturn = MOBILE_LINE_FLOW.ESIM_ONLY;
    if ((product === null || product === void 0 ? void 0 : product.skipMnp) && (product === null || product === void 0 ? void 0 : product.isEsimOnly)) flowReturn = MOBILE_LINE_FLOW.ESIM_ONLY_SKIP_MNP;
    if (product === null || product === void 0 ? void 0 : product.isWinback) flowReturn = MOBILE_LINE_FLOW.WINBACK;
    if (product === null || product === void 0 ? void 0 : product.isRequiredPortability) flowReturn = MOBILE_LINE_FLOW.PORTABILITY_REQUIRED;
    // If not have voucher card remove it from the flow
    if (!isEnabledVoucher(product)) {
        flowReturn = flowReturn.filter(function(el) {
            return el.title !== ID_FLOWS.VOUCHER;
        });
    }
    return flowReturn;
};
/**
 * Method to serializeAllSteps on finish flow
 * @param steps
 */ var seriealizeAllSteps = function(steps) {
    var serializedSteps = steps.map(function(s) {
        return s.title;
    });
    localStorage.setItem("sstps", JSON.stringify(serializedSteps));
};
/**
 * Method to de-serielize all steps on finish flow
 */ var deSeriealizeAllSteps = function(product) {
    if (!product) return [];
    var serializedSteps = getFromLocalStorageByKey("sstps");
    var removeStepById = function(allSteps, key) {
        return (allSteps === null || allSteps === void 0 ? void 0 : allSteps.filter(function(el) {
            return (el === null || el === void 0 ? void 0 : el.title) !== key;
        })) || allSteps || [];
    };
    var allMobileFlows;
    if (!serializedSteps) {
        allMobileFlows = MOBILE_LINE_FLOW.DEFAULT;
        if (!isEnabledVoucher(product)) {
            allMobileFlows = removeStepById(allMobileFlows, ID_FLOWS.VOUCHER);
        }
        return allMobileFlows;
    }
    allMobileFlows = (serializedSteps === null || serializedSteps === void 0 ? void 0 : serializedSteps.map(function(title) {
        return COMPONENTS_FLOW.find(function(el) {
            return el.title === title;
        }) || {};
    })) || [];
    if (!isEnabledVoucher(product)) {
        allMobileFlows = removeStepById(allMobileFlows, ID_FLOWS.VOUCHER);
    }
    return allMobileFlows;
};
/**
 * Check Post delivery address isset
 */ var checkShippingAddressIsIsset = function() {
    var postShipping = getFromLocalStorageByKey("postDeliveryServiceDetails");
    return !!postShipping;
};
var getShippingBillingForLoggedOrActive = function(customer) {
    var ref;
    var userType = getCurrentUserType();
    if (!(userType === IUserType.LOGGED_USER || userType === IUserType.NEXT_USER_ACTIVE)) return undefined;
    if (!customer) return undefined;
    var contactMedium = (customer === null || customer === void 0 ? void 0 : (ref = customer.owningIndividual) === null || ref === void 0 ? void 0 : ref.contactMedium) || [];
    return contactMedium === null || contactMedium === void 0 ? void 0 : contactMedium.find(function(contact) {
        return contact.type == "postalAddress";
    });
};
var getTrackError = function(error, step) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    var product = retrieveProduct();
    var pageError = [
        (error === null || error === void 0 ? void 0 : (ref = error.error) === null || ref === void 0 ? void 0 : ref.url) || "",
        (error === null || error === void 0 ? void 0 : (ref1 = error.error) === null || ref1 === void 0 ? void 0 : ref1.status) || "",
        (error === null || error === void 0 ? void 0 : error.errorType) || "",
        (error === null || error === void 0 ? void 0 : error.errorCode) || "",
        (error === null || error === void 0 ? void 0 : error.isBlocking) ? "blk" : "nblk",
        step || "", 
    ].filter(Boolean).join("_");
    var lastDexTransaction = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(NEXT_ERR_TRANSACTION_ID)) || "";
    var lastErrorMessage = (localStorage === null || localStorage === void 0 ? void 0 : localStorage.getItem(NEXT_ERR_MSG)) || "";
    var transaction_id = (error === null || error === void 0 ? void 0 : error.transaction_id) || lastDexTransaction;
    var err_errorMessage = (error === null || error === void 0 ? void 0 : error.err_errorMessage) || lastErrorMessage;
    var cartProduct = getTagging("").trackingProduct;
    var userType = getCurrentUserType();
    return _object_spread({
        flow: "mobile",
        event_name: "page_error",
        event_label_track: "page_error",
        event_category: "error",
        page_error: pageError,
        page_error_code: (error === null || error === void 0 ? void 0 : error.errorCode) || "",
        page_type: "error page",
        slug: (product === null || product === void 0 ? void 0 : product.slug) || "",
        isBlocking: (error === null || error === void 0 ? void 0 : error.isBlocking) ? "Y" : "N",
        step: step
    }, (product === null || product === void 0 ? void 0 : product.offerId) && {
        offerId: product === null || product === void 0 ? void 0 : product.offerId
    }, cartProduct && cartProduct, (cartProduct === null || cartProduct === void 0 ? void 0 : cartProduct.cart_id) && {
        cart_id: cartProduct === null || cartProduct === void 0 ? void 0 : cartProduct.cart_id
    }, transaction_id && {
        transaction_id: transaction_id
    }, (error === null || error === void 0 ? void 0 : (ref2 = error.error) === null || ref2 === void 0 ? void 0 : ref2.status) && {
        status: error === null || error === void 0 ? void 0 : (ref3 = error.error) === null || ref3 === void 0 ? void 0 : ref3.status
    }, (error === null || error === void 0 ? void 0 : (ref4 = error.error) === null || ref4 === void 0 ? void 0 : ref4.url) && {
        service_name: error === null || error === void 0 ? void 0 : (ref5 = error.error) === null || ref5 === void 0 ? void 0 : ref5.url
    }, userType && {
        user_type: userType
    }, err_errorMessage && {
        err_errorMessage: err_errorMessage
    }, (error === null || error === void 0 ? void 0 : error.err_errorCode) && {
        err_errorCode: error === null || error === void 0 ? void 0 : error.err_errorCode
    }, (error === null || error === void 0 ? void 0 : error.err_backendErrorMessage) && {
        err_backendErrorMessage: error === null || error === void 0 ? void 0 : error.err_backendErrorMessage
    }, (error === null || error === void 0 ? void 0 : error.err_backendErrorCode) && {
        err_backendErrorCode: error === null || error === void 0 ? void 0 : error.err_backendErrorCode
    }, (error === null || error === void 0 ? void 0 : error.err_backendSource) && {
        err_backendSource: error === null || error === void 0 ? void 0 : error.err_backendSource
    }, (error === null || error === void 0 ? void 0 : error.otherInfo) && {
        otherInfo: error.otherInfo
    });
};
// endregion
export { handleCRMErrorCodes, isErrorOnPaymentGeneric, isErrorOnInstant, getActiveSlideFromStorage, organizeFlowsAppend, retrieveOwningIndividual, getTagging, checkErrorCustomer, organizePaymentError, organizeOrderNotCompletedError, addRemoveVisibilityCards, seriealizeAllSteps, getSlideIndexByKey, deSeriealizeAllSteps, getUserFlow, checkShippingAddressIsIsset, getShippingBillingForLoggedOrActive, getTrackError, getUserInfo };
