var organizeCheckBoxPayment = function(paymentCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19;
    return {
        title: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref = paymentCMS.paymentsmobile) === null || ref === void 0 ? void 0 : ref.title) || "",
        description: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref1 = paymentCMS.paymentsmobile) === null || ref1 === void 0 ? void 0 : ref1.description) || "",
        items: [
            {
                title: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref2 = paymentCMS.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.choice) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.creditCard) === null || ref4 === void 0 ? void 0 : ref4.title) || "Carta di credito",
                description: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref5 = paymentCMS.paymentsmobile) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.choice) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.creditCard) === null || ref7 === void 0 ? void 0 : ref7.description) || "",
                value: "creditCard"
            },
            {
                title: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref8 = paymentCMS.paymentsmobile) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.choice) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.bankAccount) === null || ref10 === void 0 ? void 0 : ref10.title) || "Conto corrente",
                description: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref11 = paymentCMS.paymentsmobile) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.choice) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.bankAccount) === null || ref13 === void 0 ? void 0 : ref13.description) || "",
                value: "bankAccount"
            }, 
        ],
        modal: {
            textLink: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref14 = paymentCMS.paymentsmobile) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.modal) === null || ref15 === void 0 ? void 0 : ref15.linkLabel) || "",
            titleModal: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref16 = paymentCMS.paymentsmobile) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.modal) === null || ref17 === void 0 ? void 0 : ref17.title) || "",
            descriptionModal: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref18 = paymentCMS.paymentsmobile) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.modal) === null || ref19 === void 0 ? void 0 : ref19.description) || ""
        }
    };
};
var organizePaymentMethodError = function(paymentCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;
    return {
        titlePaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref = paymentCMS.paymentsmobile) === null || ref === void 0 ? void 0 : (ref1 = ref.paymentSelectionError) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        messagePaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref2 = paymentCMS.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.paymentSelectionError) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        messageSelectedPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref4 = paymentCMS.paymentsmobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.paymentError) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.notFound) === null || ref6 === void 0 ? void 0 : ref6.message) || "Siamo spiacenti ma si \xe8 verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.",
        titleSelectedPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref7 = paymentCMS.paymentsmobile) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.paymentError) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.notFound) === null || ref9 === void 0 ? void 0 : ref9.title) || "Ops!"
    };
};
export { organizePaymentMethodError, organizeCheckBoxPayment };
