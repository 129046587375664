import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect } from "react";
import { API, PAGES, trackLink } from "@vfit/shared/data-access";
import { CMS_CONFIG, organizeVoucherInsertionCard, useCmsConfig } from "@vfit/consumer/data-access";
import { IUpdateProductPriceType } from "@vfit/shared/models";
import { purify } from "@vfit/shared/themes";
import { useCheckout } from "../../../../../iBuyFixed.context";
import { useVoucherSuccessCardTagging } from "./voucherSuccessCard.tagging";
import { isNotFirstTime, setIsNotFirstTime } from "./voucherSuccessCard.utils";
import { updateProductPrice } from "../../../../../ShoppingCart/shoppingCart.utils";
export var useVoucherSuccessCard = function(param) {
    var handleOnChangeEnableGoNext = param.handleOnChangeEnableGoNext, handleGoNextSlide = param.handleGoNextSlide;
    var ref, ref1, ref2, ref3, ref4, ref5, ref6;
    var ref7 = useCheckout(), checkoutErrors = ref7.checkoutErrors, voucher = ref7.voucher, product = ref7.product, setConfiguredStickyStepperLabel = ref7.setConfiguredStickyStepperLabel, setVoucher = ref7.setVoucher, setProduct = ref7.setProduct, setIsDisabledBackButton = ref7.setIsDisabledBackButton;
    useVoucherSuccessCardTagging();
    var voucherApi = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_FIXED_VOUCHERS);
    var voucherCms = organizeVoucherInsertionCard(voucherApi, voucher.voucherId);
    var genericError = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GENERIC_ERROR_MOBILE);
    var message = ((ref1 = purify([
        (product === null || product === void 0 ? void 0 : (ref = product.vouchers) === null || ref === void 0 ? void 0 : ref.message) || ""
    ])) === null || ref1 === void 0 ? void 0 : ref1[0]) || "";
    var voucherMessage = message || "";
    var applyVoucher = function(event) {
        event.stopPropagation();
        if (voucher.validateVoucher.isSuccess && !(voucher === null || voucher === void 0 ? void 0 : voucher.isAppliedVoucher)) {
            handleOnChangeEnableGoNext === null || handleOnChangeEnableGoNext === void 0 ? void 0 : handleOnChangeEnableGoNext(false);
            setIsDisabledBackButton(true);
            setVoucher(_object_spread_props(_object_spread({}, voucher), {
                isApplyVoucher: true
            }));
        }
    };
    var resetVoucherValidation = function(event) {
        if (!(voucher === null || voucher === void 0 ? void 0 : voucher.isAppliedVoucher)) {
            var ref;
            event.stopPropagation();
            voucher === null || voucher === void 0 ? void 0 : (ref = voucher.resetData) === null || ref === void 0 ? void 0 : ref.call(voucher);
        }
    };
    var checkVoucher = function() {
        var isErrorVoucher = voucher.voucher.isError || voucher.shoppingCart.isError;
        if (isErrorVoucher) {
            var ref, ref1, ref2;
            var error = (voucher === null || voucher === void 0 ? void 0 : (ref = voucher.voucher) === null || ref === void 0 ? void 0 : ref.error) || (voucher === null || voucher === void 0 ? void 0 : (ref1 = voucher.shoppingCart) === null || ref1 === void 0 ? void 0 : ref1.error);
            var action = function() {
                var ref;
                return voucher === null || voucher === void 0 ? void 0 : (ref = voucher.resetData) === null || ref === void 0 ? void 0 : ref.call(voucher);
            };
            checkoutErrors === null || checkoutErrors === void 0 ? void 0 : (ref2 = checkoutErrors.showAndTrackError) === null || ref2 === void 0 ? void 0 : ref2.call(checkoutErrors, genericError, action, error, "", "", action);
        } else if (voucher === null || voucher === void 0 ? void 0 : voucher.isAppliedVoucher) {
            handleOnChangeEnableGoNext === null || handleOnChangeEnableGoNext === void 0 ? void 0 : handleOnChangeEnableGoNext(true);
            setIsDisabledBackButton(false);
            if (!isNotFirstTime()) {
                var ref3, ref4, ref5, ref6;
                setProduct(_object_spread_props(_object_spread({}, product, updateProductPrice(product, IUpdateProductPriceType.EDIT)), {
                    voucherPromoLabel: product === null || product === void 0 ? void 0 : (ref3 = product.vouchers) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.codeApplied) === null || ref4 === void 0 ? void 0 : ref4.replace("$VOUCHER", (voucher === null || voucher === void 0 ? void 0 : voucher.voucherId) || ""),
                    voucherPromoMessage: voucherMessage,
                    voucherPromoDetail: (ref6 = purify([
                        (product === null || product === void 0 ? void 0 : (ref5 = product.vouchers) === null || ref5 === void 0 ? void 0 : ref5.description) || ""
                    ])) === null || ref6 === void 0 ? void 0 : ref6[0]
                }));
                setIsNotFirstTime();
                handleGoNextSlide === null || handleGoNextSlide === void 0 ? void 0 : handleGoNextSlide();
            }
        }
    };
    useEffect(function() {
        checkVoucher();
    }, [
        voucher
    ]);
    useEffect(function() {
        var ref, ref1, ref2, ref3;
        setConfiguredStickyStepperLabel("");
        var goNextButton = document.getElementById("sticky-offer-next");
        var backButton = document.getElementById("back-button");
        if (!isNotFirstTime()) goNextButton === null || goNextButton === void 0 ? void 0 : goNextButton.addEventListener("click", applyVoucher);
        backButton === null || backButton === void 0 ? void 0 : backButton.addEventListener("click", resetVoucherValidation);
        if (handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
        trackLink(voucherCms === null || voucherCms === void 0 ? void 0 : (ref = voucherCms.voucherstwilight) === null || ref === void 0 ? void 0 : (ref1 = ref.insertion) === null || ref1 === void 0 ? void 0 : ref1.buttonLabel, "users", "click", "ui interaction", "link", (voucher === null || voucher === void 0 ? void 0 : voucher.voucherId) || "", voucher === null || voucher === void 0 ? void 0 : (ref2 = voucher.validateVoucher) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.data) === null || ref3 === void 0 ? void 0 : ref3.finalPrice);
        return function() {
            goNextButton === null || goNextButton === void 0 ? void 0 : goNextButton.removeEventListener("click", applyVoucher);
            backButton === null || backButton === void 0 ? void 0 : backButton.removeEventListener("click", resetVoucherValidation);
        };
    }, []);
    return {
        title: isNotFirstTime() ? (voucherCms === null || voucherCms === void 0 ? void 0 : (ref2 = voucherCms.voucherstwilight) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.success) === null || ref3 === void 0 ? void 0 : ref3.backTitle) || "" : (voucherCms === null || voucherCms === void 0 ? void 0 : (ref4 = voucherCms.voucherstwilight) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.success) === null || ref5 === void 0 ? void 0 : ref5.title) || "",
        voucherCms: voucherCms,
        isLoading: voucher.voucher.isLoading || voucher.shoppingCart.isLoading,
        discountTitle: (product === null || product === void 0 ? void 0 : (ref6 = product.vouchers) === null || ref6 === void 0 ? void 0 : ref6.discountTitle) || "",
        message: voucherMessage
    };
};
