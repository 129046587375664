import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect } from "react";
import { errorMock, getLastPaymentMethod, useGetAuthorization, useGetPaymean } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
export var useAuthorizationAsyncInformation = function() {
    var ref = useCheckout(), isStartAuthorizationAsyncInfo = ref.isStartAuthorizationAsyncInfo, authorizationAsyncInfo = ref.authorizationAsyncInfo, setUpfrontPayment = ref.setUpfrontPayment, setIsStartAuthorizationAsyncInfo = ref.setIsStartAuthorizationAsyncInfo, setAuthorizationAsyncInfo = ref.setAuthorizationAsyncInfo;
    var ref1 = useGetAuthorization({
        enabled: false
    }), authorizationData = ref1.data, authorizationError = ref1.error, isSuccessAuthorization = ref1.isSuccess, isErrorAuthorization = ref1.isError, isLoadingAuthorization = ref1.isLoading, refetchAuthorization = ref1.refetch;
    var ref2 = useGetPaymean({
        enabled: false
    }), payMeanData = ref2.data, payMeanError = ref2.error, isErrorPayMean = ref2.isError, isLoadingPayMean = ref2.isLoading, isSuccessPayMean = ref2.isSuccess, refetchPayMean = ref2.refetch;
    useEffect(function() {
        if (isLoadingPayMean) {
            setAuthorizationAsyncInfo(_object_spread_props(_object_spread({}, authorizationAsyncInfo), {
                payMean: _object_spread_props(_object_spread({}, authorizationAsyncInfo.payMean), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorPayMean) {
            setAuthorizationAsyncInfo(_object_spread_props(_object_spread({}, authorizationAsyncInfo), {
                payMean: _object_spread_props(_object_spread({}, authorizationAsyncInfo.payMean), {
                    isSuccess: false,
                    isError: true,
                    isLoading: false,
                    error: errorMock("payMean", payMeanError)
                })
            }));
        } else if (isSuccessPayMean) {
            var payMeanElement = payMeanData && getLastPaymentMethod(payMeanData);
            if (payMeanElement) {
                setUpfrontPayment(payMeanElement);
            }
            setAuthorizationAsyncInfo(_object_spread_props(_object_spread({}, authorizationAsyncInfo), {
                payMean: _object_spread_props(_object_spread({}, authorizationAsyncInfo.payMean), {
                    data: payMeanData,
                    error: undefined,
                    isError: false,
                    isSuccess: true,
                    isLoading: false
                })
            }));
        }
    }, [
        isErrorPayMean,
        isLoadingPayMean,
        isSuccessPayMean
    ]);
    useEffect(function() {
        if (isLoadingAuthorization) {
            setAuthorizationAsyncInfo(_object_spread_props(_object_spread({}, authorizationAsyncInfo), {
                authorization: _object_spread_props(_object_spread({}, authorizationAsyncInfo.authorization), {
                    error: undefined,
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                })
            }));
        } else if (isErrorAuthorization) {
            setAuthorizationAsyncInfo(_object_spread_props(_object_spread({}, authorizationAsyncInfo), {
                authorization: _object_spread_props(_object_spread({}, authorizationAsyncInfo.authorization), {
                    data: authorizationData,
                    isSuccess: false,
                    isError: true,
                    isLoading: false,
                    error: errorMock("authorization", authorizationError)
                })
            }));
        } else if (isSuccessAuthorization) {
            var ref, ref1, ref2;
            var statusAuthorization = authorizationData === null || authorizationData === void 0 ? void 0 : (ref = authorizationData.authorization) === null || ref === void 0 ? void 0 : (ref1 = ref.status) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase();
            var isErrorStatus = statusAuthorization !== "ok" && statusAuthorization !== "timeoutok";
            if (isErrorStatus && (authorizationData === null || authorizationData === void 0 ? void 0 : (ref2 = authorizationData.authorization) === null || ref2 === void 0 ? void 0 : ref2.status)) {
                var ref3;
                setAuthorizationAsyncInfo(_object_spread_props(_object_spread({}, authorizationAsyncInfo), {
                    authorization: _object_spread_props(_object_spread({}, authorizationAsyncInfo.authorization), {
                        data: authorizationData,
                        isSuccess: false,
                        isError: true,
                        isLoading: false,
                        error: errorMock("authorization", {
                            status: "200",
                            url: API.AUTHORIZATION,
                            statusText: "200"
                        }, statusAuthorization, authorizationData === null || authorizationData === void 0 ? void 0 : (ref3 = authorizationData.authorization) === null || ref3 === void 0 ? void 0 : ref3.errorCode)
                    })
                }));
            } else {
                setAuthorizationAsyncInfo(_object_spread_props(_object_spread({}, authorizationAsyncInfo), {
                    payMean: _object_spread_props(_object_spread({}, authorizationAsyncInfo.payMean), {
                        isSuccess: false,
                        isLoading: true,
                        isError: false
                    }),
                    authorization: _object_spread_props(_object_spread({}, authorizationAsyncInfo.authorization), {
                        error: undefined,
                        isError: false,
                        isSuccess: true,
                        isLoading: false
                    })
                }));
                refetchPayMean();
            }
        }
    }, [
        isErrorAuthorization,
        isLoadingAuthorization,
        isSuccessAuthorization
    ]);
    useEffect(function() {
        if (isStartAuthorizationAsyncInfo) {
            // reset authorization
            setAuthorizationAsyncInfo(_object_spread_props(_object_spread({}, authorizationAsyncInfo), {
                authorization: {
                    isLoading: false,
                    isError: false,
                    isSuccess: false,
                    data: undefined
                }
            }));
            setIsStartAuthorizationAsyncInfo(false);
            refetchAuthorization();
        }
    }, [
        isStartAuthorizationAsyncInfo
    ]);
    return null;
};
