import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { useEffect } from "react";
import { ErrorCodes, useCheckPortInFeasibilityIIMobile, useModifyPhoneNumberMobileProduct } from "@vfit/consumer/data-access";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { useCheckout } from "../../../iBuyMobile.context";
import { alreadyDonePortability } from "./usePortabilityFlow.utils";
export var usePortabilityFlow = function() {
    var ref, ref1, ref2, ref3, ref4;
    var ref5 = useCheckout(), portability = ref5.portability, portabilityStatus = ref5.portabilityStatus, setPortabilityStatus = ref5.setPortabilityStatus, ICCID = ref5.ICCID;
    var portabilityPayload = {
        currentPlanType: portability === null || portability === void 0 ? void 0 : (ref = portability.portabilityMigration) === null || ref === void 0 ? void 0 : ref.currentPlan,
        currentServiceProviderId: portability === null || portability === void 0 ? void 0 : (ref1 = portability.portabilityMigration) === null || ref1 === void 0 ? void 0 : ref1.currentProvider,
        currentServiceProviderIdName: portability === null || portability === void 0 ? void 0 : (ref2 = portability.portabilityMigration) === null || ref2 === void 0 ? void 0 : ref2.currentProvider,
        msisdn: portability.portabilityNumber,
        isSimAvailable: ICCID && (ICCID === null || ICCID === void 0 ? void 0 : ICCID.trim()) !== "" ? "true" : "false",
        transferRemainingBalance: String(portability === null || portability === void 0 ? void 0 : (ref3 = portability.portabilityMigration) === null || ref3 === void 0 ? void 0 : ref3.transferBalance),
        simCardNumber: (ICCID === null || ICCID === void 0 ? void 0 : ICCID.trim()) || ""
    };
    var flowEnabler = portability.portabilityWant && !alreadyDonePortability() && (portability === null || portability === void 0 ? void 0 : (ref4 = portability.portabilityOtp) === null || ref4 === void 0 ? void 0 : ref4.isVerified) && (portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isStartPortIn) || false;
    var isStartFinalPortIn = portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isStartFinalPortIn;
    var ref6 = useCheckPortInFeasibilityIIMobile(), mutatePortIn = ref6.mutate, checkPortInData = ref6.data, isErrorPortIn = ref6.isError, isLoadingPortIn = ref6.isLoading, isSuccessPortIn = ref6.isSuccess, resetPortIn = ref6.reset;
    var ref7 = useModifyPhoneNumberMobileProduct(), mutateModify = ref7.mutate, isErrorModify = ref7.isError, isSuccessModify = ref7.isSuccess;
    useEffect(function() {
        if (flowEnabler) {
            mutatePortIn(portabilityPayload);
        }
    }, [
        flowEnabler
    ]);
    useEffect(function() {
        if (isLoadingPortIn) {
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                portInData: undefined,
                isLoadingCheck: true,
                isSuccessCheck: false,
                isErrorCheck: false
            }));
        } else if (isSuccessPortIn) {
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                portInData: checkPortInData,
                isLoadingCheck: false,
                isSuccessCheck: true,
                isErrorCheck: false
            }));
        }
    }, [
        isLoadingPortIn,
        isSuccessPortIn
    ]);
    useEffect(function() {
        var ref, ref1, ref2, ref3, ref4, ref5, ref6;
        var dataPortIn = getFromLocalStorageByKey("checkPortInFeasibilityIIQuery");
        if ((isSuccessPortIn && checkPortInData || ((ref = dataPortIn.resultDescription) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === "success") && dataPortIn && !isErrorPortIn && ((ref1 = dataPortIn) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.resultDescription) === null || ref2 === void 0 ? void 0 : ref2.toLowerCase()) !== "failure" && !((ref3 = dataPortIn) === null || ref3 === void 0 ? void 0 : ref3.error) && isStartFinalPortIn) {
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoading: true,
                isSuccess: false,
                isError: false
            }));
            mutateModify(portabilityPayload);
        }
        if ((ref4 = dataPortIn) === null || ref4 === void 0 ? void 0 : ref4.error) {
            var ref7;
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoadingCheck: false,
                isSuccessCheck: false,
                isErrorCheck: true,
                errorMessage: (ref7 = dataPortIn) === null || ref7 === void 0 ? void 0 : ref7.errorMessage
            }));
        }
        if (isSuccessPortIn && (!dataPortIn || ((ref5 = dataPortIn) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.resultDescription) === null || ref6 === void 0 ? void 0 : ref6.toLowerCase()) === "failure")) {
            var ref8, ref9, ref10, ref11, ref12, ref13;
            resetPortIn();
            var errorText = "";
            if (((ref8 = dataPortIn) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.resultMessages) === null || ref9 === void 0 ? void 0 : (ref10 = ref9[0]) === null || ref10 === void 0 ? void 0 : ref10.messageCode) === "9000054") {
                errorText = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if ((ref11 = dataPortIn) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.resultMessages) === null || ref12 === void 0 ? void 0 : (ref13 = ref12[0]) === null || ref13 === void 0 ? void 0 : ref13.messageDescription) {
                errorText = ErrorCodes.NUMBER_ERROR;
            } else {
                errorText = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
            }
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                portInData: checkPortInData,
                isLoadingCheck: false,
                isSuccessCheck: false,
                isErrorCheck: true,
                errorMessage: errorText
            }));
        }
    }, [
        isLoadingPortIn,
        isSuccessPortIn,
        isErrorPortIn,
        isStartFinalPortIn
    ]);
    useEffect(function() {
        if (!(portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isErrorCheck) && isSuccessModify) {
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoading: false,
                isSuccess: true,
                isError: false
            }));
        }
        if (isErrorModify) {
            setPortabilityStatus(_object_spread_props(_object_spread({}, portabilityStatus), {
                isLoading: false,
                isSuccess: false,
                isError: true
            }));
        }
    }, [
        portabilityStatus === null || portabilityStatus === void 0 ? void 0 : portabilityStatus.isErrorCheck,
        isSuccessModify,
        isErrorModify
    ]);
    return null;
};
