import { PAYMENT_PRESENT_CC_BANK_ACCONT } from "@vfit/consumer/data-access";
import { EMethodCode } from "@vfit/shared/models";
var organizeCheckBoxPayment = function(paymentCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7;
    return {
        title: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref = paymentCMS.paymentsmobile) === null || ref === void 0 ? void 0 : ref.title) || "",
        description: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref1 = paymentCMS.paymentsmobile) === null || ref1 === void 0 ? void 0 : ref1.description) || "",
        modal: {
            textLink: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref2 = paymentCMS.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.modal) === null || ref3 === void 0 ? void 0 : ref3.linkLabel) || "",
            titleModal: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref4 = paymentCMS.paymentsmobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.modal) === null || ref5 === void 0 ? void 0 : ref5.title) || "",
            descriptionModal: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref6 = paymentCMS.paymentsmobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.modal) === null || ref7 === void 0 ? void 0 : ref7.description) || ""
        }
    };
};
var organizeCheckBoxPaymentItems = function(paymentCMS, availablePaymentMethods, selectionIndex) {
    var items = [];
    var paymentPresentInNextOrLogged = localStorage.getItem(PAYMENT_PRESENT_CC_BANK_ACCONT);
    availablePaymentMethods.forEach(function(paymentMethod, index) {
        var ref, ref1;
        var foundedPaymentCMS = paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref = paymentCMS.paymentsmobile) === null || ref === void 0 ? void 0 : (ref1 = ref.choice) === null || ref1 === void 0 ? void 0 : ref1[paymentMethod === null || paymentMethod === void 0 ? void 0 : paymentMethod.methodCode];
        if (foundedPaymentCMS) {
            var ref2;
            items.push({
                title: foundedPaymentCMS === null || foundedPaymentCMS === void 0 ? void 0 : foundedPaymentCMS.title,
                description: (foundedPaymentCMS === null || foundedPaymentCMS === void 0 ? void 0 : foundedPaymentCMS.description) || "",
                value: paymentMethod.methodCode,
                extra: index === 0 ? {
                    text: paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref2 = paymentCMS.paymentsmobile) === null || ref2 === void 0 ? void 0 : ref2.defaultChoice,
                    color: "#fff",
                    extraClass: "extraClass"
                } : undefined,
                selection: selectionIndex === index
            });
        }
    });
    var walletIndex = items === null || items === void 0 ? void 0 : items.findIndex(function(item) {
        var ref, ref1;
        return (item === null || item === void 0 ? void 0 : (ref = item.value) === null || ref === void 0 ? void 0 : ref.toLowerCase()) === (EMethodCode === null || EMethodCode === void 0 ? void 0 : (ref1 = EMethodCode.WALLET) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase());
    });
    if (walletIndex !== -1) {
        var walletElement = items.splice(walletIndex, 1)[0];
        items.push(walletElement);
    }
    if (paymentPresentInNextOrLogged) {
        if (paymentPresentInNextOrLogged === "1") {
            return items.filter(function(el) {
                return el.value === EMethodCode.CREDIT_CARD;
            });
        }
        if (paymentPresentInNextOrLogged === "2") {
            return items.filter(function(el) {
                return el.value !== EMethodCode.WALLET;
            });
        }
    }
    return items;
};
var organizePaymentMethodError = function(paymentCMS) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17;
    return {
        titleSelectionPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref = paymentCMS.paymentsmobile) === null || ref === void 0 ? void 0 : (ref1 = ref.paymentSelectionError) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        messageSelectionPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref2 = paymentCMS.paymentsmobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.paymentSelectionError) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        buttonLabelSelectionPayment: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref4 = paymentCMS.paymentsmobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.paymentSelectionError) === null || ref5 === void 0 ? void 0 : ref5.buttonLabel) || "",
        iconSelectionPayment: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref6 = paymentCMS.paymentsmobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.paymentSelectionError) === null || ref7 === void 0 ? void 0 : ref7.icon) || "",
        messageSelectedPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref8 = paymentCMS.paymentsmobile) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.paymentError) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.notFound) === null || ref10 === void 0 ? void 0 : ref10.message) || "Siamo spiacenti ma si \xe8 verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.",
        titleSelectedPaymentError: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref11 = paymentCMS.paymentsmobile) === null || ref11 === void 0 ? void 0 : (ref12 = ref11.paymentError) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.notFound) === null || ref13 === void 0 ? void 0 : ref13.title) || "Ops!",
        buttonLabelSelectedPayment: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref14 = paymentCMS.paymentsmobile) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.paymentError) === null || ref15 === void 0 ? void 0 : ref15.buttonLabel) || "",
        iconSelectedPayment: (paymentCMS === null || paymentCMS === void 0 ? void 0 : (ref16 = paymentCMS.paymentsmobile) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.paymentError) === null || ref17 === void 0 ? void 0 : ref17.icon) || ""
    };
};
var SKELETON_SHAPE = {
    margins: [
        60,
        30
    ],
    tabletMargins: [
        32,
        15
    ],
    groups: [
        {
            heights: 60,
            marginBottom: 15
        },
        {
            heights: 90,
            marginBottom: 32
        },
        {
            heights: 60,
            repeat: 4,
            gap: 15
        }, 
    ]
};
export { SKELETON_SHAPE, organizePaymentMethodError, organizeCheckBoxPayment, organizeCheckBoxPaymentItems };
