import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-business-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { checkWindow, getFromLocalStorageByKey } from "@vfit/shared/data-access";
import { checkIsApp, CMS_ID, DXL_NBO_CAMPAIGNS, DXL_NBO_CAMPAIGN, FLOW_TYPE, getAddressInfoByPlaceId, getDXLProductPrice, getRedirectCoverage, OFFER_ID, PLACE_ID, retrieveAppSession, retrieveProduct, updateProduct } from "@vfit/consumer/data-access";
import { FLOW_TYPE_DYNAMIC_SHOPPING_CART } from "@vfit/shared/models";
import { errorManager, ErrorService, LoggerInstance } from "@vfit/shared/providers";
export var DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE = {
    isLoading: true,
    isSuccess: false,
    isError: false,
    flowType: "",
    errorCode: -1,
    product: undefined
};
export var DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_ERROR = {
    isLoading: false,
    isSuccess: false,
    isError: true,
    errorCode: -1,
    flowType: "",
    product: undefined
};
export var DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS = {
    isLoading: false,
    isSuccess: true,
    isError: false,
    errorCode: -1,
    flowType: "",
    product: undefined
};
// region IADD Logic
export var iAddProductNotFound = function() {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: "Ops!",
        message: "Si \xe8 verificato un errore durante il recupero del prodotto. Riprova pi\xf9 tardi",
        actionEvent: function() {
            localStorage.removeItem("shoppingCart");
            window.location.reload();
        },
        actionText: "Riprova",
        disableTrack: true
    });
};
var retrieveIAddOffer = function(product, allProducts, dxlNboCampaign, serviceabilityIds) {
    var alternativeProductOfferList = (product === null || product === void 0 ? void 0 : product.alternativeProductOfferList) || [];
    if (!serviceabilityIds || serviceabilityIds && serviceabilityIds.length === 0) return null;
    // filter alternative offers inside the product
    var filteredAlternativeProducts = (allProducts === null || allProducts === void 0 ? void 0 : allProducts.filter(function(prod) {
        return alternativeProductOfferList.includes(prod === null || prod === void 0 ? void 0 : prod.cmsId);
    })) || [];
    // search Dxl product from filteredAlternative
    if (serviceabilityIds.includes(dxlNboCampaign === null || dxlNboCampaign === void 0 ? void 0 : dxlNboCampaign.bundlePO)) {
        var dxlProduct = (filteredAlternativeProducts === null || filteredAlternativeProducts === void 0 ? void 0 : filteredAlternativeProducts.find(function(prod) {
            var ref;
            return ((ref = prod.offerId) === null || ref === void 0 ? void 0 : ref.toString()) === (dxlNboCampaign === null || dxlNboCampaign === void 0 ? void 0 : dxlNboCampaign.bundlePO);
        })) || null;
        if (dxlProduct && (dxlNboCampaign === null || dxlNboCampaign === void 0 ? void 0 : dxlNboCampaign.bundlePO)) {
            localStorage.setItem(DXL_NBO_CAMPAIGN, JSON.stringify(dxlNboCampaign));
            // Update DXL Price
            var price = getDXLProductPrice(dxlNboCampaign);
            return _object_spread_props(_object_spread({}, dxlProduct), {
                offerId: +dxlNboCampaign.bundlePO,
                price: price
            });
        }
    }
    return (filteredAlternativeProducts === null || filteredAlternativeProducts === void 0 ? void 0 : filteredAlternativeProducts.find(function(prod) {
        var ref;
        return serviceabilityIds.includes(prod === null || prod === void 0 ? void 0 : (ref = prod.offerId) === null || ref === void 0 ? void 0 : ref.toString()) && !(prod === null || prod === void 0 ? void 0 : prod.isLockInProduct);
    })) || null;
};
export var getIAddConfiguration = function(allProducts) {
    var ref;
    // Mock product IAdd => 51...
    var product = retrieveProduct();
    if (!product) return undefined;
    var dxlCampaignsFixed = getFromLocalStorageByKey(DXL_NBO_CAMPAIGNS);
    var offeringServiceability = getFromLocalStorageByKey("offeringServiceability");
    if (!offeringServiceability || (allProducts === null || allProducts === void 0 ? void 0 : allProducts.length) === 0) return null;
    // All Serviceability bundle ids
    var serviceabilityIds = offeringServiceability === null || offeringServiceability === void 0 ? void 0 : (ref = offeringServiceability.offeringServiceabilityItem) === null || ref === void 0 ? void 0 : ref.map(function(item) {
        var ref, ref1;
        return item === null || item === void 0 ? void 0 : (ref = item.offering) === null || ref === void 0 ? void 0 : (ref1 = ref.id) === null || ref1 === void 0 ? void 0 : ref1.toString();
    });
    // Search Dxl ID bundle
    var foundedDxlNbo = (dxlCampaignsFixed === null || dxlCampaignsFixed === void 0 ? void 0 : dxlCampaignsFixed.find(function(dxlCampaignsFixedProduct) {
        return serviceabilityIds.includes(dxlCampaignsFixedProduct.bundlePO);
    })) || (dxlCampaignsFixed === null || dxlCampaignsFixed === void 0 ? void 0 : dxlCampaignsFixed[0]);
    var backupOffer = retrieveIAddOffer(product, allProducts, foundedDxlNbo, serviceabilityIds);
    LoggerInstance.debug("IADD PRODUCT", backupOffer);
    if (!backupOffer) return undefined;
    updateProduct(_object_spread({}, backupOffer));
    return backupOffer;
};
// endregion
export var retrieveCartInfo = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(allProducts) {
        var ref, queryString, placeId, offerId, cmsId, flowType, address, product;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ;
                    if (checkWindow()) {
                        _ctx.next = 3;
                        break;
                    }
                    return _ctx.abrupt("return");
                case 3:
                    queryString = new URLSearchParams(window === null || window === void 0 ? void 0 : (ref = window.location) === null || ref === void 0 ? void 0 : ref.search);
                    placeId = queryString.get(PLACE_ID);
                    offerId = queryString.get(OFFER_ID);
                    cmsId = queryString.get(CMS_ID);
                    flowType = queryString.get(FLOW_TYPE);
                    placeId && localStorage.setItem("placeId", placeId);
                    if (checkIsApp()) {
                        retrieveAppSession();
                    }
                    if (flowType) {
                        _ctx.next = 12;
                        break;
                    }
                    return _ctx.abrupt("return", false);
                case 12:
                    if (!(flowType == FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_FIXED)) {
                        _ctx.next = 15;
                        break;
                    }
                    if (!(!placeId && (!cmsId || !offerId))) {
                        _ctx.next = 15;
                        break;
                    }
                    return _ctx.abrupt("return", false);
                case 15:
                    if (!(flowType == FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_MOBILE)) {
                        _ctx.next = 18;
                        break;
                    }
                    if (!(!cmsId && !offerId)) {
                        _ctx.next = 18;
                        break;
                    }
                    return _ctx.abrupt("return", false);
                case 18:
                    ;
                    ;
                    if (!placeId) {
                        _ctx.next = 24;
                        break;
                    }
                    _ctx.next = 23;
                    return getAddressInfoByPlaceId(placeId);
                case 23:
                    address = _ctx.sent;
                case 24:
                    if (cmsId || offerId) {
                        product = allProducts.find(function(productLocal) {
                            var ref, ref1;
                            if (cmsId) return (productLocal === null || productLocal === void 0 ? void 0 : (ref = productLocal.cmsId) === null || ref === void 0 ? void 0 : ref.toString()) == cmsId;
                            return (productLocal === null || productLocal === void 0 ? void 0 : (ref1 = productLocal.offerId) === null || ref1 === void 0 ? void 0 : ref1.toString()) == offerId;
                        });
                        if (product) updateProduct(product);
                    }
                    return _ctx.abrupt("return", _object_spread_props(_object_spread({}, address), {
                        product: product,
                        redirectCoverage: getRedirectCoverage(product.redirectCoveragePath),
                        onConfirmAddress: !!product && !!address,
                        alternativeProductList: (product === null || product === void 0 ? void 0 : product.alternativeProductOfferList) || [],
                        flowType: flowType
                    }));
                case 26:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function retrieveCartInfo(allProducts) {
        return _ref.apply(this, arguments);
    };
}();
