import { ErrorCodes } from "@vfit/consumer/data-access";
export var checkError = function(errorCode, handleOnClose, errorMessge) {
    var title = (errorMessge === null || errorMessge === void 0 ? void 0 : errorMessge.title) || "";
    var message = (errorMessge === null || errorMessge === void 0 ? void 0 : errorMessge.description) || "";
    var actionText = (errorMessge === null || errorMessge === void 0 ? void 0 : errorMessge.buttonLabel) || "";
    var onClose = handleOnClose;
    var actionEvent = handleOnClose;
    if (errorCode === ErrorCodes.FISCAL_CODE_UNDERAGE) {
        message = "Devi essere maggiorenne per proseguire";
        onClose = undefined;
        actionEvent = undefined;
    }
    if (errorCode === ErrorCodes.FISCAL_CODE_ERROR) {
        message = "Il codice fiscale non \xe8 valido. Inserisci un codice fiscale valido per proseguire";
        onClose = undefined;
        actionEvent = undefined;
    }
    return {
        title: title,
        message: message,
        actionText: actionText,
        onClose: onClose,
        actionEvent: actionEvent
    };
};
export var organizeNameFiscalCodeMobile = function(nameFiscalCodeCms, product) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31;
    return {
        title: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref = nameFiscalCodeCms.personalinfomobile) === null || ref === void 0 ? void 0 : (ref1 = ref.firstCard) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
        description: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref2 = nameFiscalCodeCms.personalinfomobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.firstCard) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
        firstNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref4 = nameFiscalCodeCms.personalinfomobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selectInput) === null || ref5 === void 0 ? void 0 : ref5.firstName) || "",
        lastNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref6 = nameFiscalCodeCms.personalinfomobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.selectInput) === null || ref7 === void 0 ? void 0 : ref7.lastName) || "",
        fiscalCodeLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref8 = nameFiscalCodeCms.personalinfomobile) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.selectInput) === null || ref9 === void 0 ? void 0 : ref9.fiscalCode) || "",
        buttonLabel: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref10 = nameFiscalCodeCms.personalinfomobile) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.firstCard) === null || ref11 === void 0 ? void 0 : ref11.fcMissingLabel) || "",
        legacyPopup: {
            legacyPopupIsOpen: true,
            legacyPopupTitle: (product === null || product === void 0 ? void 0 : (ref12 = product.commonOverride) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.legacyCtc) === null || ref13 === void 0 ? void 0 : ref13.title) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref14 = nameFiscalCodeCms.personalinfomobile) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.clientRecognition) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.redirect) === null || ref16 === void 0 ? void 0 : ref16.title) || "",
            legacyPopupMessage: (product === null || product === void 0 ? void 0 : (ref17 = product.commonOverride) === null || ref17 === void 0 ? void 0 : (ref18 = ref17.legacyCtc) === null || ref18 === void 0 ? void 0 : ref18.description) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref19 = nameFiscalCodeCms.personalinfomobile) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.clientRecognition) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.redirect) === null || ref21 === void 0 ? void 0 : ref21.description) || "",
            legacyPopupActionText: (product === null || product === void 0 ? void 0 : (ref22 = product.commonOverride) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.legacyCtc) === null || ref23 === void 0 ? void 0 : ref23.buttonLabel) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref24 = nameFiscalCodeCms.personalinfomobile) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.clientRecognition) === null || ref25 === void 0 ? void 0 : (ref26 = ref25.redirect) === null || ref26 === void 0 ? void 0 : ref26.buttonLabel) || "",
            legacyPopupUrl: (product === null || product === void 0 ? void 0 : (ref27 = product.commonOverride) === null || ref27 === void 0 ? void 0 : (ref28 = ref27.legacyCtc) === null || ref28 === void 0 ? void 0 : ref28.url) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref29 = nameFiscalCodeCms.personalinfomobile) === null || ref29 === void 0 ? void 0 : (ref30 = ref29.clientRecognition) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.redirect) === null || ref31 === void 0 ? void 0 : ref31.url) || ""
        }
    };
};
export var organizePrsonalInfoMobile = function(dataFromCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20;
    return {
        title: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref = dataFromCms.personalinfomobile) === null || ref === void 0 ? void 0 : (ref1 = ref.clientLogged) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.personalInfo) === null || ref2 === void 0 ? void 0 : ref2.title) || "Dati personali",
        description: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref3 = dataFromCms.personalinfomobile) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.clientLogged) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.personalInfo) === null || ref5 === void 0 ? void 0 : ref5.description) || "Queste sono le tue informazioni personali.",
        bottomText: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref6 = dataFromCms.personalinfomobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.clientLogged) === null || ref7 === void 0 ? void 0 : (ref8 = ref7.personalInfo) === null || ref8 === void 0 ? void 0 : ref8.bottomText) || "Vuoi effettuare l’ordine per un’altra persona?",
        lableName: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref9 = dataFromCms.personalinfomobile) === null || ref9 === void 0 ? void 0 : (ref10 = ref9.clientLogged) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.personalInfo) === null || ref11 === void 0 ? void 0 : ref11.labelName) || "Il tuo nome e cognome",
        lableFiscalCode: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref12 = dataFromCms.personalinfomobile) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.clientLogged) === null || ref13 === void 0 ? void 0 : (ref14 = ref13.personalInfo) === null || ref14 === void 0 ? void 0 : ref14.lableFiscalCode) || "Il tuo codice fiscale",
        action: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref15 = dataFromCms.personalinfomobile) === null || ref15 === void 0 ? void 0 : (ref16 = ref15.clientLogged) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.personalInfo) === null || ref17 === void 0 ? void 0 : ref17.textAction) || "Logout",
        url: (dataFromCms === null || dataFromCms === void 0 ? void 0 : (ref18 = dataFromCms.personalinfomobile) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.clientLogged) === null || ref19 === void 0 ? void 0 : (ref20 = ref19.personalInfo) === null || ref20 === void 0 ? void 0 : ref20.url) || "https://www.vodafone.it/area-utente/fai-da-te/Common/PaginaUnicadiLogin.html"
    };
};
export var organizeRetriveFiscalCodeMobile = function(nameFiscalCodeCms) {
    var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13, ref14, ref15, ref16, ref17, ref18, ref19, ref20, ref21, ref22, ref23, ref24, ref25, ref26, ref27, ref28, ref29, ref30, ref31, ref32, ref33, ref34, ref35, ref36, ref37, ref38, ref39, ref40, ref41;
    return {
        retriveFiscalCode: {
            title: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref = nameFiscalCodeCms.personalinfomobile) === null || ref === void 0 ? void 0 : (ref1 = ref.fiscalCode) === null || ref1 === void 0 ? void 0 : ref1.title) || "",
            description: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref2 = nameFiscalCodeCms.personalinfomobile) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.fiscalCode) === null || ref3 === void 0 ? void 0 : ref3.description) || "",
            firstName: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref4 = nameFiscalCodeCms.personalinfomobile) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.selectInput) === null || ref5 === void 0 ? void 0 : ref5.firstName) || "",
            lastName: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref6 = nameFiscalCodeCms.personalinfomobile) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.selectInput) === null || ref7 === void 0 ? void 0 : ref7.lastName) || "",
            birthDate: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref8 = nameFiscalCodeCms.personalinfomobile) === null || ref8 === void 0 ? void 0 : (ref9 = ref8.selectInput) === null || ref9 === void 0 ? void 0 : ref9.birthdate) || "",
            gender: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref10 = nameFiscalCodeCms.personalinfomobile) === null || ref10 === void 0 ? void 0 : (ref11 = ref10.selectInput) === null || ref11 === void 0 ? void 0 : ref11.gender) || "",
            nationality: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref12 = nameFiscalCodeCms.personalinfomobile) === null || ref12 === void 0 ? void 0 : (ref13 = ref12.selectInput) === null || ref13 === void 0 ? void 0 : ref13.country) || "",
            birthPlace: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref14 = nameFiscalCodeCms.personalinfomobile) === null || ref14 === void 0 ? void 0 : (ref15 = ref14.selectInput) === null || ref15 === void 0 ? void 0 : ref15.city) || "",
            birthNation: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref16 = nameFiscalCodeCms.personalinfomobile) === null || ref16 === void 0 ? void 0 : (ref17 = ref16.selectInput) === null || ref17 === void 0 ? void 0 : ref17.birthNation) || "Nazione di nascita",
            errorMessage: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref18 = nameFiscalCodeCms.personalinfomobile) === null || ref18 === void 0 ? void 0 : (ref19 = ref18.firstCard) === null || ref19 === void 0 ? void 0 : ref19.allFieldsAreRequired) || "",
            buttonLabel: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref20 = nameFiscalCodeCms.personalinfomobile) === null || ref20 === void 0 ? void 0 : (ref21 = ref20.fiscalCode) === null || ref21 === void 0 ? void 0 : ref21.buttonLabel) || ""
        },
        nameFiscalCodeCard: {
            title: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref22 = nameFiscalCodeCms.personalinfomobile) === null || ref22 === void 0 ? void 0 : (ref23 = ref22.firstCard) === null || ref23 === void 0 ? void 0 : ref23.title) || "",
            titleReadOnly: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref24 = nameFiscalCodeCms.personalinfomobile) === null || ref24 === void 0 ? void 0 : (ref25 = ref24.firstCard) === null || ref25 === void 0 ? void 0 : ref25.titleReadOnly) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref26 = nameFiscalCodeCms.personalinfomobile) === null || ref26 === void 0 ? void 0 : (ref27 = ref26.firstCard) === null || ref27 === void 0 ? void 0 : ref27.title) || "",
            description: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref28 = nameFiscalCodeCms.personalinfomobile) === null || ref28 === void 0 ? void 0 : (ref29 = ref28.firstCard) === null || ref29 === void 0 ? void 0 : ref29.description) || "",
            descriptionReadOnly: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref30 = nameFiscalCodeCms.personalinfomobile) === null || ref30 === void 0 ? void 0 : (ref31 = ref30.firstCard) === null || ref31 === void 0 ? void 0 : ref31.descriptionReadOnly) || (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref32 = nameFiscalCodeCms.personalinfomobile) === null || ref32 === void 0 ? void 0 : (ref33 = ref32.firstCard) === null || ref33 === void 0 ? void 0 : ref33.description) || "",
            firstNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref34 = nameFiscalCodeCms.personalinfomobile) === null || ref34 === void 0 ? void 0 : (ref35 = ref34.selectInput) === null || ref35 === void 0 ? void 0 : ref35.firstName) || "",
            lastNameLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref36 = nameFiscalCodeCms.personalinfomobile) === null || ref36 === void 0 ? void 0 : (ref37 = ref36.selectInput) === null || ref37 === void 0 ? void 0 : ref37.lastName) || "",
            fiscalCodeLable: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref38 = nameFiscalCodeCms.personalinfomobile) === null || ref38 === void 0 ? void 0 : (ref39 = ref38.selectInput) === null || ref39 === void 0 ? void 0 : ref39.fiscalCode) || "",
            buttonLabel: (nameFiscalCodeCms === null || nameFiscalCodeCms === void 0 ? void 0 : (ref40 = nameFiscalCodeCms.personalinfomobile) === null || ref40 === void 0 ? void 0 : (ref41 = ref40.firstCard) === null || ref41 === void 0 ? void 0 : ref41.fcMissingLabel) || ""
        }
    };
};
