import { ALL_EVENTS, checkBypassApiCallFromSIA, createPayloadMobile, errorMock, useGetCountry, useShoppingCartMutation } from "@vfit/consumer/data-access";
import { useEffect, useState } from "react";
import { getFromLocalStorageByKey } from "@vfit/shared/data-access";
export function useShoppingCartInfo(product, customerId, isUserLogged, isDisabledCreateCart) {
    var ref = useState(true), isLoading = ref[0], setIsLoading = ref[1];
    var ref1 = useState(false), isError = ref1[0], setIsError = ref1[1];
    var ref2 = useState(""), errorMessage = ref2[0];
    var ref3 = useState(false), isCompletedSilentLogin = ref3[0], setIsCompletedSilentLogin = ref3[1];
    var ref4 = useGetCountry({
        enabled: false
    }), isCountriesLoading = ref4.isLoading, refetchCountry = ref4.refetch, isSuccessGetCountry = ref4.isSuccess, isErrorGetCountry = ref4.isError;
    var shoppingCart = getFromLocalStorageByKey("shoppingCart");
    var shoppingCartMutation = useShoppingCartMutation();
    /**
   * Triggering shoppingCart Mutation if the product matches the serviceability
   */ useEffect(function() {
        if (isCompletedSilentLogin && !isDisabledCreateCart && !shoppingCartMutation.isLoading && !shoppingCartMutation.isSuccess && isSuccessGetCountry) {
            var payload = createPayloadMobile(product === null || product === void 0 ? void 0 : product.offerId, product === null || product === void 0 ? void 0 : product.planId.toString(), isUserLogged, customerId || "", product === null || product === void 0 ? void 0 : product.isRequiredPortability, (product === null || product === void 0 ? void 0 : product.isRequiredPortability) ? {
                dummyNumber: product === null || product === void 0 ? void 0 : product.portabilityDummyNumber,
                dummyOperator: product === null || product === void 0 ? void 0 : product.portabilityDummyOperator
            } : undefined);
            if (payload) shoppingCartMutation.mutate(payload);
            else {
                setIsError(true);
            }
        }
    }, [
        isCompletedSilentLogin,
        isSuccessGetCountry
    ]);
    /**
   * Checking if the shoppingCart or deleteShoppingCart mutations are getting an error
   */ useEffect(function() {
        if (shoppingCartMutation.isError || isErrorGetCountry) {
            setIsError(true);
            setIsLoading(false);
        }
    }, [
        shoppingCartMutation.isError,
        isErrorGetCountry
    ]);
    useEffect(function() {
        if (isDisabledCreateCart) {
            if (!isCountriesLoading) {
                setIsLoading(false);
            }
        } else if (shoppingCartMutation.isSuccess && !!shoppingCart && !isCountriesLoading) {
            setIsLoading(false);
        }
    }, [
        !!shoppingCart,
        shoppingCartMutation.isSuccess,
        isCountriesLoading
    ]);
    useEffect(function() {
        var listener = function() {
            setIsCompletedSilentLogin(true);
        };
        if (!checkBypassApiCallFromSIA()) {
            var countries = getFromLocalStorageByKey("getCountry");
            if (!countries) refetchCountry();
        }
        if (typeof document !== "undefined") {
            document.addEventListener(ALL_EVENTS.SILENT_LOGIN_COMPLETE, listener);
        }
        return function() {
            document.removeEventListener(ALL_EVENTS.SILENT_LOGIN_COMPLETE, listener);
        };
    }, []);
    return {
        isCompletedSilentLogin: isCompletedSilentLogin,
        isLoading: isLoading,
        isError: isError,
        errorMessage: errorMessage,
        errorCart: (shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.error) ? errorMock("shoppingCart", shoppingCart === null || shoppingCart === void 0 ? void 0 : shoppingCart.error, "", "", "", true) : null
    };
}
